import { Controller } from "stimulus"
import StimulusReflex from 'stimulus_reflex'

export default class extends Controller {
    static targets = [ "events", "info", "description" ]

    connect() {
        StimulusReflex.register(this)
    }

    initialize() {
        this.playgroundId = this.data.get("id")
        this.show(this.eventsTarget, this.eventsVisible)
        this.show(this.infoTarget, this.infoVisible)
        this.show(this.descriptionTarget, this.descriptionVisible)
    }

    // reflexes

    startPlayground(e) {
        this.disableButton(e)
        this.stimulate('Playgrounds#start_playground', e.target, this.playgroundId)
    }

    stopPlayground(e) {
        if (this.locked && !confirm("Are you sure? This playground has been locked.")) {
            return
        }
        this.disableButton(e)
        this.stimulate('Playgrounds#stop_playground', e.target, this.playgroundId)
    }

    lockPlayground(e) {
        this.stimulate('Playgrounds#lock_playground', e.target, this.playgroundId, true)
    }

    unlockPlayground(e) {
        this.stimulate('Playgrounds#lock_playground', e.target, this.playgroundId, false)
    }

    // toggles

    toggleEvents(e) {
        e.preventDefault()
        this.eventsVisible=1-this.eventsVisible
        this.show(this.eventsTarget, this.eventsVisible)
    }

    toggleInfo(e) {
        e.preventDefault()
        this.infoVisible=1-this.infoVisible
        this.show(this.infoTarget, this.infoVisible)
    }

    toggleDescription(e) {
        e.preventDefault()
        this.descriptionVisible=1-this.descriptionVisible
        this.show(this.descriptionTarget, this.descriptionVisible)
    }

    // getters&setters

    get eventsVisible() {
        const val = parseInt(sessionStorage.getItem('eventsVisible'))
        if (isNaN(val)) {
            return 0
        }
        return val
    }

    get infoVisible() {
        const val = parseInt(sessionStorage.getItem('infoVisible'))
        if (isNaN(val)) {
            return 0
        }
        return val
    }

    get descriptionVisible() {
        const val = parseInt(sessionStorage.getItem('descriptionVisible'))
        if (isNaN(val)) {
            return 0
        }
        return val
    }

    get locked() {
        return this.data.get('locked') == 'true'
    }

    set eventsVisible(value) {
        sessionStorage.setItem('eventsVisible', value)
    }

    set infoVisible(value) {
        sessionStorage.setItem('infoVisible', value)
    }

    set descriptionVisible(value) {
        sessionStorage.setItem('descriptionVisible', value)
    }

    // helpers

    disableButton(e) {
        const btn = $(e.target).closest('button')
        btn.disabled = true
    }

    show(target, show) {
        if (show) {
            target.classList.remove('d-none')
        } else {
            target.classList.add('d-none')
        }
    }
}

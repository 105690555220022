// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start();
require("turbolinks").start();
require("@rails/activestorage").start();
require("channels");
require("jquery");
require("leaflet");
require("chosen-js");
require("mousetrap");
require("bootstrap");
// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

// import "bootstrap";
import './src/application.scss';
import 'leaflet/dist/leaflet.css';
import "@fortawesome/fontawesome-free/js/all";
import 'chosen-js/chosen.min.css';

function handleLocationHash() {
  var h = window.location.href.split('#')[1];
  if (h) {
    $.getScript(h);
    return true;
  } else {
    return false;
  }
}

document.addEventListener("turbolinks:load", function() {
  handleLocationHash();

  $('body').on('click', 'a[data-remote=true][data-pushstate=true]', function() {
    history.pushState(null, '', '#' + $(this).prop("pathname"));
  });
  $(window).on('popstate', function() {
    if (!handleLocationHash()) {
      // No hash.
      // Turbolinks.visit(window.location.href);
    }
  });
  //
  // enable chosen-js
  $('.chosen-select').chosen({
    no_results_text: 'No results matched',
    width: '100%',
  });
  // TODO: Doesn't work, tried https://stackoverflow.com/questions/60470917/tooltip-is-not-a-function-rails-6-webpack
  // $('[data-toggle="tooltip"]').tooltip({ html: true });

  $('.chosen-container > .chosen-choices').addClass('form-control');

});

import "controllers"
